<template>
  <v-container
    class="d-flex flex-column justify-content-between h-100 form-container"
  >
    <v-row class="flex-grow-0" :class="{ 'text-right': $vuetify.rtl }">
      <v-col v-for="(field, index) in formFields" :key="index" cols="12">
        <!-- text field -->
        <FieldContainer
          v-if="field.field_type === 'text' || field.field_type === 'number'"
          :label="$t(field.field_name)"
          :divider="!(index === formFields.length - 1)"
          :disabled="!Boolean(field.is_editable)"
        >
          <v-text-field
            v-model="formData[field.field_name]"
            clearable
            dense
            outlined
            :type="field.field_type"
            class="rounded-lg h-50px"
            append-icon="mdi-barcode-scan"
            :disabled="!Boolean(field.is_editable)"
            :ref="field.field_name"
            @click:append="onEnterInput(field)"
            @keyup.enter="onEnterInput(field)"
            @blur="handleFormValidation(field)"
            @input="handleFormValidation(field)"
            @clear="handleFormValidation(field)"
            :error-messages="errorMessages[field.field_name]"
          ></v-text-field>
        </FieldContainer>
        <!-- swtich -->
        <FieldContainer
          v-else-if="field.field_type === 'boolean'"
          :label="$t(field.field_name)"
          :divider="!(index === formFields.length - 1)"
          :disabled="!Boolean(field.is_editable)"
        >
          <v-switch
            v-model="formData[field.field_name]"
            inset
            :true-value="1"
            :false-value="0"
          ></v-switch>
        </FieldContainer>
        <!-- select -->
        <FieldContainer
          v-else-if="field.field_type === 'select'"
          :label="$t(field.field_name)"
          :divider="!(index === formFields.length - 1)"
          :disabled="!Boolean(field.is_editable)"
        >
          <v-select
            class="rounded-lg h-50px"
            v-model="formData[field.field_name]"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            :items="field.values"
            :disabled="!Boolean(field.is_editable)"
            @keyup.enter="onEnterInput(field)"
            @blur="handleFormValidation(field)"
            @input="handleFormValidation(field)"
            :error-messages="errorMessages[field.field_name]"
          ></v-select>
        </FieldContainer>
      </v-col>
    </v-row>
    <!--begin::Actions-->
    <div
      class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center mt-15"
    >
      <button
        type="reset"
        class="btn btn-light me-3 px-5 py-3 ls1"
        @click="reset"
      >
        {{ $t('clear') }}
      </button>
      <button
        @click.prevent="submit"
        type="submit"
        class="btn btn-info px-5 py-3 ls1"
      >
        {{ submitButtonText }}
      </button>
    </div>
    <!--end::Actions-->
  </v-container>
</template>

<script>
/**
 * This component is responsible for creating dynamic form fields.
 * It expects an array of objects representing the form with the following structure:
 *
 * @typedef {Object} FormField
 * @property {string} field_text - The text to display for the form field.
 * @property {string} field_name - The name of the form field.
 * @property {string} field_type - The type of the form field (e.g., 'text', 'select').
 * @property {boolean} is_required - Indicates if the form field is required.
 * @property {boolean} is_editable - Indicates if the form field is editable.
 * @property {boolean} is_parseable - Indicates if the form field value is parseable.
 * @property {string} default_value - The default value for the form field.
 * @property {number} display_order - The order in which the form field should be displayed.
 * @property {string} url - The URL to hit after scanning or pressing enter, if applicable.
 * @property {Array<{value: string, label: string}>} values - The values for select fields.
 *
 * @param {FormField[]} fields - The array of form field objects.
 *
 * @example
 * // Example usage:
 * formFields = [
{
        field_text: "Service id",
        field_name: "service_id",
        field_type: "text",
        is_required: true,
        is_editable: true,
        default_value: "",
        display_order: 1,
        url: "",
        values: "",
      },
 * ];
 */
import FieldContainer from "@/own/components/FieldContainer.vue";

export default {
  name: "FormBuilder",
  components: {
    FieldContainer,
  },
  props: {
    formFields: { type: Array, required: true },
    defaultValues: { type: Object, required: true },
    submitButtonText: { type: String, default: "Submit" },
    onSubmit: { type: Function, required: true },
  },
  data: () => ({
    formData: {},
    errorMessages: {},
  }),
  methods: {
    async onEnterInput(field) {
      const moveCursor = () => {
        //move cursor
        const displayFields = this.formFields; // Access computed property

        const currentIndex = displayFields.findIndex(
          (item) => item.field_name === field.field_name
        );

        for (
          let index = currentIndex + 1;
          index < displayFields.length;
          index++
        ) {
          if (
            displayFields[index].is_editable &&
            (displayFields[index].field_type === "text" ||
              displayFields[index].field_type === "number")
          ) {
            const ref = displayFields[index].field_name;
            this.$refs[ref][0].focus();
            break;
          }
        }
      };

      if (this.formData[field.field_name]) {
        if (field.is_master_scan) {
          await field?.onEnterKeypress();
        }
        moveCursor();
      }
    },
    handleFormValidation(field) {
      const { field_name, is_required, field_type } = field;
      this.errorMessages[field_name] = [];

      if (
        is_required &&
        (this.formData[field_name] === "" || this.formData[field_name] === null)
      ) {
        this.errorMessages[field_name].push(this.$t("this_field_is_required"));
      } else if (field_type === "number" && isNaN(this.formData[field_name])) {
        this.errorMessages[field_name].push(this.$t("this_must_be_a_number"));
      } else {
        this.errorMessages[field_name] = [];
      }
    },
    isFormValid() {
      return !Object.values(this.errorMessages).some(
        (item) => item?.length > 0
      );
    },
    getDefaultValues() {
      this.formFields.forEach((field) => {
        this.$set(this.formData, field.field_name, field.default_value || "");
        this.$set(this.errorMessages, field.field_name, []);
      });
    },
    reset() {
      this.formFields.forEach((field) => {
        this.formData[field.field_name] = field.default_value || "";
        this.errorMessages[field.field_name] = [];
      });

      if (
        this.formFields.length > 0 &&
        (this.formFields[0].field_type === "text" ||
          this.formFields[0].field_type === "number")
      ) {
        this.$refs[this.formFields[0].field_name][0].focus();
      }
    },
    submit() {
      // validate form
      this.formFields.forEach((item) => this.handleFormValidation(item));
      if (this.isFormValid()) {
        this.onSubmit(this.formData);
      }
    },
  },
  created() {
    this.getDefaultValues();
  },
};
</script>
