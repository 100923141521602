<template>
  <div class="mr-sm-2 mb-1 mr-0 pa-0">
    <button
      :disabled="!packageCode || !packageId"
      @click.prevent="toggleModal"
      class="btn btn--export-filter"
    >
      {{ $t("update_package") }}
    </button>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="850"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">
            {{ $t("update_package_code") }}
          </h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <FormBuilder
            :formFields="formFields"
            :defaultValues="defaultValues"
            :onSubmit="submitForm"
          ></FormBuilder>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import FormBuilder from "@/own/components/FormBuilder.vue";

export default {
  name: "PackageDimensionUpdate",
  props: {
    submitUrl: { required: true },
    packageCode: { required: true },
    packageId: { required: true },
    uoms: { required: true },
    asnId: { required: true },
  },
  components: { FormBuilder },
  data() {
    return {
      dialog: false,

      formData: {
        service_id: null,
        abbreviation: null,
        length: null,
      },

      defaultValues: {},
    };
  },
  methods: {
    async toggleModal() {
      this.dialog = !this.dialog;
    },

    submitForm(data) {
      let payload = {
        package_type_id: this.packageId,
        warehouse_id: this.selectedWarehouse,
        id: this.asnId,
        ...data,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(this.submitUrl, payload)
        .then(() => {
          swalEdited();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    actionItem: function () {
      return this.$store.getters.getItemForAction;
    },
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    formFields: function () {
      return [
        {
          field_text: "",
          field_name: "package_code",
          field_type: "text",
          is_required: true,
          is_editable: false,
          default_value: this.packageCode,
          display_order: 1,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "dimension_uom_id",
          field_type: "select",
          is_required: true,
          is_editable: true,
          default_value: this.defaultDimensionUom,
          display_order: 2,
          url: "",
          values: this.uoms,
        },
        {
          field_text: "",
          field_name: "length",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: "",
          display_order: 3,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "height",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: "",
          display_order: 4,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "width",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: "",
          display_order: 5,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "weight_uom_id",
          field_type: "select",
          is_required: true,
          is_editable: true,
          default_value: this.defaultWeighUom,
          display_order: 6,
          url: "",
          values: this.uoms,
        },
        {
          field_text: "",
          field_name: "weight",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: "",
          display_order: 7,
          url: "",
          values: "",
        },
      ];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    defaultWeighUom: function () {
      return this.uoms.find((uom) => uom.text === "kg" || uom.text === "Kg");
    },
    defaultDimensionUom: function () {
      return this.uoms.find((uom) => uom.text === "cm" || uom.text === "Cm");
    },
  },
};
</script>
