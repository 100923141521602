<template>
  <!--begin::Datepicker-->
  <date-range-picker
    class="w-100"
    ref="picker"
    opens="left"
    :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
    :close-on-esc="false"
    :showDropdowns="true"
    control-container-class="form-control max-h-45px"
    v-model="dateRange"
    :single-date-picker="true"
    :ranges="false"
    :disabled="Boolean(disabled)"
  >
    <!--            @select="selectDate"-->
    <template v-slot:input="picker">
      <div class="h-100 d-flex align-center">
        <span class="svg-icon svg-icon-2 mr-2">
          <v-icon size="22">mdi-calendar</v-icon>
        </span>

        <input
          type="text"
          class="custom-input"
          placeholder="Select a date"
          :value="picker.rangeText === ' - ' ? '' : picker.rangeText"
        />
      </div>
    </template>

    <template v-slot:footer="picker">
      <div class="drp-buttons py-4 px-6 poppins d-flex">
        <button
          type="button"
          class="btn btn-light ls1 py-2 px-7"
          @click="
            () => {
              picker.clickCancel;
              clear();
            }
          "
        >
          Cancel</button
        ><button
          @click="
            () => {
              picker.clickApply;
              onChangeDateRange();
            }
          "
          type="button"
          class="btn btn--export-filter ls1 py-2 px-4"
        >
          Apply
        </button>
      </div>
    </template>
  </date-range-picker>
  <!--end::Datepicker-->
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "SingleDatepicker",
  components: { DateRangePicker },
  props: ["disabled", "date", "onDateChange", "name"],
  data() {
    return {
      dateRange: { startDate: null, endDate: null },
    };
  },
  methods: {
    getDateModified() {
      let startDate, endDate;
      const pattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;

      if (pattern.test(this.dateRange.startDate)) {
        startDate = this.slashSeperatedToISO(this.dateRange.startDate);
        endDate = this.slashSeperatedToISO(this.dateRange.endDate);
      } else {
        startDate = this.extendedToISO(this.dateRange.startDate);
        endDate = this.extendedToISO(this.dateRange.endDate);
      }
      return [startDate, endDate];
    },
    slashSeperatedToISO(date) {
      const parts = date.split("/");
      const year = parts[2];
      const month = (parts[0].length == 1 ? "0" : "") + parts[0];
      const day = (parts[1].length == 1 ? "0" : "") + parts[1];
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    extendedToISO(val) {
      const date = new Date(val);
      const formattedDate = date.toISOString().substr(0, 10);
      return formattedDate;
    },
    clear() {
      this.dateRange = { startDate: null, endDate: null };
    },
    onChangeDateRange() {
      const [startDate] = this.getDateModified();
      this.onDateChange(startDate, this.name);
    },
  },
  watch: {
    // dateRange() {
    //   const [startDate] = this.getDateModified();
    //   this.onDateChange(startDate, this.name);
    // },
    date(val) {
      if (val === null) {
        this.clear();
      } else {
        const formattedDate = new Date(val);
        this.dateRange = { startDate: formattedDate, endDate: formattedDate };
      }
    },
  },
};
</script>
